import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Categories`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Data Sources`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > General`}</li><li>{`Contact information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`IVRsView > listing`}</li><li>{`IVRsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > General`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`LeadManagementView > listing`}</li><li>{`LeadManagementView > rules`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Credits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Contact information > Contact finalization`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > agg-metrics`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > metric`}</li><li>{`ContactsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Device information > None`}</li><li>{`Session information > Device information`}</li><li>{`Visit > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`LeadManagementView > listing`}</li><li>{`LeadManagementView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Session information > Device information`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Device information > None`}</li><li>{`Session information > Device information`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`LeadManagementView > listing`}</li><li>{`LeadManagementView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Device information > None`}</li><li>{`Session information > Device information`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`LeadManagementView > listing`}</li><li>{`LeadManagementView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Device information > None`}</li><li>{`Session information > Device information`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`LeadManagementView > listing`}</li><li>{`LeadManagementView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Device information > None`}</li><li>{`Session information > Device information`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Device information > None`}</li><li>{`Session information > Device information`}</li><li>{`Visit > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Customer information > Customer identification`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Session information > General`}</li><li>{`Visit > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`VisitsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Contact information > Location information`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`LeadManagementView > listing`}</li><li>{`LeadManagementView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Contact information > General`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`LeadManagementView > listing`}</li><li>{`LeadManagementView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search terms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Session information > General`}</li><li>{`Visit > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > None`}</li><li>{`Session information > General`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > listing`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > None`}</li><li>{`Session information > General`}</li><li>{`Visit > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Contact information > Location information`}</li><li>{`Location information > None`}</li><li>{`Visit > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Contact information > Location information`}</li><li>{`Location information > None`}</li><li>{`Visit > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia VID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > None`}</li><li>{`Customer information > Customer identification`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`VisitorsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coremedia CID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > General`}</li><li>{`Contact information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Identification type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > None`}</li><li>{`Customer information > Customer identification`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > None`}</li><li>{`Customer information > Segments`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > None`}</li><li>{`Customer information > Segments`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > None`}</li><li>{`Session information > General`}</li><li>{`Visit > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > None`}</li><li>{`Session information > General`}</li><li>{`Visit > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > rules`}</li><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of interactions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Chat > None`}</li><li>{`Contact information > Specific by contact type`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-metrics`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > metric`}</li><li>{`ContactsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nodes used`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Chat > None`}</li><li>{`Contact information > Specific by contact type`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > agg-metrics`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > metric`}</li><li>{`ContactsView > rules`}</li><li>{`IVRsView > agg-metrics`}</li><li>{`IVRsView > metric`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Has transcript?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Contact information > Other services`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > agg-metrics`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > metric`}</li><li>{`ContactsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time on node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Chat > None`}</li><li>{`Contact information > General`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-fields`}</li><li>{`ContactsView > agg-metrics`}</li><li>{`ContactsView > listing`}</li><li>{`ContactsView > metric`}</li><li>{`ContactsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Contact information > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > rules`}</li><li>{`VisitorsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Chat > None`}</li><li>{`Voice > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-metrics`}</li><li>{`ContactsView > metric`}</li><li>{`OnsiteCampaignsView > agg-metrics`}</li><li>{`OnsiteCampaignsView > metric`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Lead information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContactsView > agg-metrics`}</li><li>{`ContactsView > metric`}</li><li>{`LeadManagementView > agg-metrics`}</li><li>{`LeadManagementView > listing`}</li><li>{`LeadManagementView > metric`}</li><li>{`LeadManagementView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Content Event > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Visit > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`VisitsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Remote IP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Visit > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`ContentFeedbackView > rules`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Impressions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Broadcast > None`}</li><li>{`Content Feedback > All`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-metrics`}</li><li>{`ContentFeedbackView > metric`}</li><li>{`OnsiteCampaignsView > agg-metrics`}</li><li>{`OnsiteCampaignsView > metric`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Date > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`VisitsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Date > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`VisitsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Date > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`VisitsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Date > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`VisitsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Date > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`VisitsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Date > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`VisitsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Date > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`ContentFeedbackView > agg-fields`}</li><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`VisitsView > agg-fields`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IVR duration (s)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Contact information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`IVRsView > agg-metrics`}</li><li>{`IVRsView > listing`}</li><li>{`IVRsView > metric`}</li><li>{`IVRsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voice Recognition Accuracy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Contact information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`IVRsView > agg-metrics`}</li><li>{`IVRsView > listing`}</li><li>{`IVRsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total contacts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`LeadManagementView > agg-metrics`}</li><li>{`LeadManagementView > metric`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`Campaign information > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`OnsiteCampaignsView > agg-fields`}</li><li>{`OnsiteCampaignsView > rules`}</li><li>{`VisitorsView > agg-fields`}</li><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{` > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`VisitorsView > listing`}</li><li>{`VisitorsView > rules`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Session duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`All > None`}</li><li>{`Visitors information > None`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li>{`VisitsView > agg-fields`}</li><li>{`VisitsView > agg-metrics`}</li><li>{`VisitsView > metric`}</li><li>{`VisitsView > rules`}</li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      